<template>
	<v-card max-width="800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span class="headline">{{ $t('faculties.faculty') }}</span>
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" outlined />
				<v-text-field :value="`[${university.acronym}] ${university.name}`" :label="$t('universities.university')" outlined readonly />
				<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="rules.acronym" outlined />
				<v-text-field v-model="discord" :label="$t('faculties.discord')" :counter="discordMaxLength" :rules="rules.discord" outlined />
				<v-select
					v-model="domains"
					:items="availableDomains"
					:label="$t('faculties.studentEmailDomain')"
					item-text="name"
					item-value="id"
					return-object
					multiple
					chips
					prepend-icon="mdi-at"
					outlined
				/>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn color="blue darken-1" text @click="createNew()">{{ $t('settings.add') }}</v-btn>
			<v-btn color="blue darken-1" text @click="createNewAndAnother()">{{ $t('settings.addAndAnother') }}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FacultyAddForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			name: '',
			acronym: '',
			discord: '',
			domains: [],
			rules: {
				name: [
					(v) => !!v || 'Name is required.',
					(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
				],
				acronym: [
					(v) => !!v || 'Acronym is required.',
					(v) => (v && v.length <= this.acronymMaxLength) || `Acronym must be less than ${this.acronymMaxLength} characters`
				],
				discord: [(v) => 0 < v.length <= this.discordMaxLength || `Discord must be less than ${this.discordMaxLength} characters`]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		discordMaxLength() {
			return 128
		},
		...mapGetters({
			university: 'universities/university',
			availableDomains: 'domains/domains'
		})
	},
	created() {
		this.fetchDomains().then(() => {
			this.loading = false
		})
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			return this.addFaculty({
				universityID: this.university.id,
				name: this.name,
				acronym: this.acronym,
				discord: this.discord,
				domains: this.domains
			}).then((data) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'StudiesUniversity', params: { id: this.university.id } })
				}
				return data
			})
		},
		createNewAndAnother() {
			this.remainAfterwards = true
			this.createNew().then((data) => {
				if (data.success) {
					this.name = ''
					this.acronym = ''
					this.discord = ''
					this.domains = []
					this.remainAfterwards = false
					window.scrollTo(0, 0)
				}
			})
		},
		...mapActions('faculties', ['addFaculty']),
		...mapActions('domains', ['fetchDomains'])
	}
}
</script>
